import { createApp as createVueApp, h, reactive, version } from 'vue';
import { SitecoreJssPlaceholderPlugin } from '@sitecore-jss/sitecore-jss-vue';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { DefaultApolloClient } from '@vue/apollo-composable';
import {
  createMetaManager as createVueMetaManager,
  defaultConfig,
  deepestResolver,
} from 'vue-meta';
import { createRouter } from './routing/router';
import { createGraphQLProvider } from './lib/ApolloProvider';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import componentFactory from './temp/componentFactory';
import SitecoreJssStorePlugin from './lib/SitecoreJssStorePlugin';
import AppRoot from './AppRoot';
import mitt from 'mitt';
import GlobalVariables from './helpers/GlobalVariables';

const createMetaManager = (isSSR) =>
  createVueMetaManager(
    isSSR,
    {
      ...defaultConfig,
    },
    deepestResolver
  );

const setSubdomainStyles = (brand) => {
  // Import the shared styles
  require('../node_modules/@pon/subdomain/dist/assets/css/subdomain.css');
  // Since we are using require, we cannot dynamically set the brand theme css.
  // We have to make a condition and import them one by one.
  if (brand == 'vwlkw') {
    require('../node_modules/@pon/subdomain/dist/brands/vwlkw/subdomain-vwlkw.css');
  }
}

// createApp is invoked by both the main and SSR entry points, so the two entry points can use the same app creation process.
export function createApp(initialState, i18n, isSSR, formsSettings) {
  const router = createRouter(isSSR);
  const metaManager = createMetaManager(isSSR);
  const apolloClient = createGraphQLProvider(initialState);
  const settings = reactive({
    brand: process.env.VUE_APP_BRAND,
    productionTip: false,
    subdomain: {
      enabled: formsSettings.subdomainEnabled === 'true',
    },
  });

  const vueOptions = {
    router,
    render: () => h(AppRoot),
    i18n,
  };

  const app = createVueApp(vueOptions);
  app.provide(DefaultApolloClient, apolloClient);
  app.use(router);
  app.use(SitecoreJssStorePlugin);
  app.use(SitecoreJssPlaceholderPlugin, { componentFactory });
  app.use(i18n);
  app.use(metaManager);
  app.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_RECAPTCHA_API_KEY,
    loaderOptions: {
      useRecaptchaNet: true,
    },
  });

  if (process.env.VUE_APP_AI_CONNECTION_STRING) {
    const appInsights = new ApplicationInsights({
      config: {
        connectionString: process.env.VUE_APP_AI_CONNECTION_STRING,
      },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
    app.config.globalProperties.$appInsights = appInsights;
  }

  // if there is an initial state defined, push it into the store, where it can be referenced by interested components.
  if (initialState) {
    app.config.globalProperties.$jss.store.setSitecoreData(initialState);
  }

  if (formsSettings?.formPath) {
    app.config.globalProperties.$jss.store.setFormPath(formsSettings.formPath);
  }

  if (formsSettings?.formApiEndpoint) {
    app.config.globalProperties.$jss.store.setApiEndpoint(formsSettings.formApiEndpoint);
  }

  if (formsSettings?.isFormStandalone) {
    app.config.globalProperties.$jss.store.setIsFormStandalone(formsSettings.isFormStandalone);
  }

  if (settings.subdomain?.enabled) {
    setSubdomainStyles(settings.brand);
  }

  app.config.globalProperties.$settings = settings;

  const emitter = mitt();
  app.config.globalProperties.$eventBus = emitter;
  GlobalVariables.variables = app.config.globalProperties;

  console.log('Vue version:', version);

  return { app, router, graphQLProvider: apolloClient };
}
