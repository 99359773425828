<template>
  <not-found v-if="notFound === true" :context="appState.sitecoreContext" />
  <route-loading v-else-if="loading" />
  <layout v-else :route="appState.routeData" />
</template>

<script>
import { isEditorActive } from '@sitecore-jss/sitecore-jss-vue';
import config from '../temp/config';
import Layout from '../Layout.vue';
import NotFound from '../NotFound';
import RouteLoading from './RouteLoading';
import { AxiosDataFetcher } from '@sitecore-jss/sitecore-jss';

// Dynamic route handler for Sitecore items.
// Because JSS app routes are defined in Sitecore, traditional static routing isn't enough -
// we need to load dynamic route data from Sitecore when the client side route changes.
// So vue-router delegates all route rendering to this handler, which attempts to get the right
// route data from Sitecore - and if none exists, renders the not found component.

// i.e. In vue-router you have the routes array, which contains objects for each route, like this:
// const routes = [
//   { path: '/foo', component: Foo },
//   { path: '/bar', component: Bar }
// ]
// in JSS implementation, the component is always RouteHandler, and it will pass data for the correct route into the layout component

/**
 * Gets route data from Sitecore. This data is used to construct the component layout for a JSS route.
 * @param {string} route Route path to get data for (e.g. /about)
 * @param {string} language Language to get route data in (content language, e.g. 'en')
 * @param {dataApi.LayoutServiceRequestOptions} options Layout service fetch options
 */

export default {
  name: 'RouteHandler',
  data() {
    const state = { notFound: null, defaultLanguage: config.defaultLanguage, loading: true };

    // To take advantage of Vue's reactive data for tracking app state changes, we need
    // to reference the same `state` object that the $jss store references in order for mutations to be observed.
    // $jss is attached to the App instance via `SitecoreJssPlugin`.
    const appState = this.$jss.store.state;

    // if the app state has routeData, we don't need to load it and don't need a loading screen
    if (appState.routeData) {
      state.loading = false;
    }

    // route path from vue router - if route was resolved, it's not a 404
    // comment valtech: ^ above not entirely correct: we've got a route from vue router, but that doesn't mean the route corresponds with a page. So it may end up in a 404.
    if (this.route && (this.route.params.sitecoreRoute || this.route.path === '/')) {
      state.notFound = false;
    } else {
      state.notFound = true;
    }

    // if we have an initial SSR state, and that state doesn't have a valid route data,
    // then this is a 404 route.
    if (!appState.routeData) {
      // Comment Valtech 24 feb 2020:
      // data() gets parsed before created() is executed. On first pass, appState may not have routeData yet, so it would then set state.notFound to true here
      // when created() is called, that will call updateRouteData, which (after fetching) fills appState's routeData
      // I've left the commented code here as a reference, just in case I misunderstood things...
      // state.notFound = true;
    }

    // if we have initial context data, and that context data has a language defined, set the default language
    // (this makes the language of content follow the Sitecore context language cookie)
    // note that a route-based language (i.e. /de-DE) will override this default; this is for home.
    if (appState.sitecoreContext && appState.sitecoreContext.language) {
      state.defaultLanguage = appState.sitecoreContext.language;
    }

    return { ...state, appState };
  },
  props: {
    route: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    // if no existing routeData is present (from SSR), get Layout Service fetching the route data
    if (!this.appState.routeData) {
      this.updateRouteData();
    }
    // tell app to sync its current language with the route language
    this.updateLanguage();
  },
  inject: {
    changeAppLanguage: {
      type: Function,
    },
  },
  methods: {
    adjustSitecoreConfiguration(route, fetchOptionsParam, language) {
      const fetchOptions = fetchOptionsParam;
      const key = {};

      if (config.sitecoreApiKey) {
        key.sc_apikey = config.sitecoreApiKey;
      }

      fetchOptions.params = {
        item: route,
        ...key,
        sc_lang: language,
      };

      return fetchOptions;
    },

    getRouteData(route, language, apiOverride) {
      const url = `${apiOverride || config.sitecoreApiHost}/sitecore/api/layout/render/jss`;
      let fetchOptions = {
        url,
      };

      fetchOptions = this.adjustSitecoreConfiguration(route, fetchOptions, language);

      return new AxiosDataFetcher({
        onReq: (config) => {
          const urlReq = {
            ...config,
            ...fetchOptions,
          };
          return urlReq;
        },
        onReqError: (error) => {
          // request error handling
          return Promise.reject(error);
        },
        onRes: (response) => {
          return response;
        },
        onResError: (error) => {
          if (error.response && error.response.status === 404 && error.response.data) {
            return error.response.data;
          }
          return null;
        },
      }).fetch(url);
    },

    /**
     * Loads route data from Sitecore Layout Service into appState.routeData
     */
    updateRouteData() {
      let sitecoreRoutePath = '/';

      // First check if we have a route. If not we go to the formPath if available
      if (this.route.params.sitecoreRoute) {
        sitecoreRoutePath = this.route.params.sitecoreRoute.join('/');
      } else if (this.appState.formPath) {
        sitecoreRoutePath = this.appState.formPath;
      }

      if (!sitecoreRoutePath.startsWith('/')) {
        sitecoreRoutePath = `/${sitecoreRoutePath}`;
      }

      const language =
        this.route.params.lang || this.appState.sitecoreContext.language || this.defaultLanguage;
      this.loading = true;

      this.getRouteData(sitecoreRoutePath, language, this.appState.apiEndpoint).then(
        (routeData) => {
          if (
            routeData &&
            (routeData.data !== null || routeData.data !== '') &&
            routeData.data?.sitecore?.route
          ) {
            // Update the JSS store instance with the fetched data.
            // This will signal the RouteHandler to update/re-render, as well as any components
            // that are referencing the JSS store instance in their `data` object.
            this.$jss.store.setSitecoreData(routeData.data);
            this.notFound = false;
          } else {
            // this.$jss.store.setSitecoreData(routeData);
            this.notFound = true;
          }
          this.loading = false;
        }
      );
    },
    /**
     * Updates the current app language to match the route data.
     */
    updateLanguage() {
      const newLanguage =
        this.route.params.lang || this.appState.sitecoreContext.language || this.defaultLanguage;
      // `changeAppLanguage` is "inject"-ed from AppRoot
      this.changeAppLanguage(newLanguage);
    },
  },
  watch: {
    // watch for a change in the 'route' prop
    route(newRoute, oldRoute) {
      // if the route contains a hash value, assume the URL is a named anchor/bookmark link, e.g. /page#anchorId.
      // in that scenario, we don't want to fetch new route data but instead allow default browser behavior.
      if (newRoute.hash !== '' && newRoute.path === oldRoute.path) {
        return;
      }
      // if in Sitecore editor - force reload instead of route data update
      // avoids confusing Sitecore's editing JS
      if (isEditorActive()) {
        window.location.assign(newRoute.path);
        return;
      }

      this.updateLanguage();
      this.updateRouteData();
    },
  },
  components: {
    Layout,
    NotFound,
    RouteLoading,
  },
};
</script>
