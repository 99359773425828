<template>
  <div>
    <vue-form
      :form="fields"
      :sitecore-api-host="apiHost"
      :custom-field-factory="customFieldFactory"
      :params="params"
      :translations="translations"
      @submit-success="handleSuccess($event)"
      @submit-fields-errors="handleFieldsErrors($event)"
      @submit-form-error="handleFormError($event)"
      @form-submit="onFormSubmit"
      @formState="formState"
    />
    <error-component v-if="formError" />
  </div>
</template>

<script>
/* eslint-disable-next-line */
import scConfig from '@/temp/config';
import { VueForm } from '../../Library/VueForm';
import ErrorComponent from '../Elements/ErrorComponent.vue';
import FieldFactory from '@/forms/fieldFactory';
import addEventToDigitalData from '../../helpers/DigitalData';

export default {
  name: 'JSSForm',
  components: {
    VueForm,
    ErrorComponent,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const requiredTranslation = `{0} ${this.$t('forms-validations-required')}`;
    const params = scConfig.sitecoreApiKey
      ? {
          sc_apikey: scConfig.sitecoreApiKey,
        }
      : {};
    return {
      config: scConfig,
      customFieldFactory: FieldFactory,
      params: params,
      translations: {
        validations: {
          required: requiredTranslation.replace(/ +/g, ' '),
        },
      },
      formError: false,
    };
  },
  mounted() {
    addEventToDigitalData('formStart', {
      eventAction: this.fields?.metadata?.name || '',
      eventCategory: 'forms',
    });
  },
  computed: {
    apiHost() {
      return this.$jss.store.state.apiEndpoint || this.config.sitecoreApiHost;
    },
  },
  methods: {
    onFormSubmit() {
      this.formError = false;
    },
    handleSuccess(fields) {
      const formState = {};

      Object.values(fields).forEach((field) => {
        formState[field.fieldName] = field.value;
      });

      addEventToDigitalData('formSubmit', {
        eventAction: this.fields?.metadata?.name || '',
        eventCategory: 'forms',
        ...formState,
      });
    },
    handleFieldsErrors(errors) {
      Object.values(errors).forEach((error) => {
        addEventToDigitalData('formError', {
          eventAction: this.fields?.metadata?.name || '',
          eventCategory: 'forms',
          eventLabel: 'form error',
          formName: this.fields?.metadata?.name || '',
          formFieldError: error.field.model.name,
          formErrorMessage: error.errors.value[0],
        });
      });
    },
    handleFormError() {
      this.formError = true;
    },
    formState(fields) {
      const formState = {};

      Object.values(fields).forEach((field) => {
        formState[field.fieldName] = Array.isArray(field.value)
          ? field.value.join(',')
          : field.value;
      });
      addEventToDigitalData('formFocusChange', {
        eventAction: this.fields?.metadata?.name || '',
        eventCategory: 'forms',
        ...formState,
      });
    },
  },
};
</script>
