import 'cross-fetch/polyfill'; // Apollo uses `fetch`, which needs a polyfill for node and older browsers.
import { InMemoryCache, ApolloClient } from '@apollo/client/core';
import { sha256 } from 'js-sha256';

/*
  INTROSPECTION DATA
  See https://www.apollographql.com/docs/react/recipes/fragment-matching.html
  This enables the Apollo cache to process fragments on interface types correctly.
  If this file does not exist, you may need to run the `jss graphql:update` script.
*/
import introspectionQueryResultData from '../temp/GraphQLFragmentTypes.json';

/*
  QUERY LINK SELECTION
  A link is transport which GraphQL queries are pushed across.
  You have many choices.
  See the apollo-link documentation for more details.
*/

// choose between a basic HTTP link to run queries...
// import { createHttpLink } from 'apollo-link-http';
// const link = createHttpLink({ uri: endpoint });

// ...or a batched link (multiple queries within 10ms all go in one HTTP request)
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { BatchHttpLink } from '@apollo/client/link/batch-http';

export default function (endpoint, ssr, initialCacheState) {
  /* HTTP link selection: default to batched + APQ */
  const link = createPersistedQueryLink({ sha256 }).concat(
    new BatchHttpLink({
      uri: endpoint,
      credentials: 'include',
    })
  );

  const possibleTypes = {};

  introspectionQueryResultData.__schema.types.forEach((supertype) => {
    possibleTypes[supertype.name] = supertype.possibleTypes.map((subtype) => subtype.name);
  });

  const cache = new InMemoryCache({
    possibleTypes,
  });

  return new ApolloClient({
    ssrMode: ssr,
    ssrForceFetchDelay: 100,
    link,
    cache: cache.restore(initialCacheState),
  });
}
